<template>
  <b-container class="p-0" fluid>
    <b-row :class="collapsable ? 'cursor-pointer' : ''" no-gutters @click="toggleCollapse">
      <b-col :style="{ color : activeColor, borderColor: activeColor}" class="text-left content-title hortilio-title pl-50" cols="10">
          <span v-if="title">
            {{ title }}
          </span>
        <slot v-else name="title"/>
      </b-col>
      <b-col :style="{ color : activeColor, borderColor: activeColor}" class="left-space pr-1" cols="2">
        <slot name="titleSpace"/>
      </b-col>
    </b-row>

    <b-collapse ref="textBlockContent">
      <b-row class="info-row" style="margin-top: 5px;" no-gutters>
        <b-col>
          <div>{{ text }}</div>
          <slot/>
        </b-col>
      </b-row>
    </b-collapse>
  </b-container>
</template>

<script type="text/javascript">
import {BButton, BCol, BCollapse, BContainer, BRow} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BCollapse,
    BContainer
  },
  props: {
    title: {
      type: String,
      default: null
    },
    text: {
      type: String,
      default: ""
    },
    image: {
      type: String,
      default: ""
    },
    activeColor: {
      type: String,
      default: "var(--primary)"
    },
    collapsable: {
      type: Boolean,
      default: false
    },
    collapseOpen: {
      type: Boolean,
      default: true
    },
  },
  mounted() {
    if (!this.collapsable || this.collapseOpen) {
      this.$refs.textBlockContent.show = true
    }
  },
  methods: {
    toggleCollapse() {
      if (this.collapsable) {
        this.$refs.textBlockContent.toggle()
      }
    }
  }
}
</script>

<style lang="scss" media="screen">

.content-title {
  border-bottom: 1px solid;
  font-size: 1.7rem !important;
  font-weight: bold;
}

.info-row {
  margin-bottom: 10px;
}

.left-space {
  border-bottom: 1px solid;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

</style>
