import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

//todo : noLoggedAccess => check validity of attribute for each page

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior (to, from, savedPosition) {
        // If route hashes are not similar or names are different
        if ((!to.hash.includes(from.hash) && !from.hash.includes(to.hash) )|| to.name !== from.name) {
            // Scroll to top
            return { x: 0, y: 0 }
        }

        // If savedPosition is present, means this popstate navigation,
        // we can return savedPosition to keep scroll at that position
        if (savedPosition) {
            return savedPosition
        }

        // Otherwise, no specific scroll behavior
        return false
    },
    routes: [
        {path: '/index.html', redirect: '/'},
        {
            path: '/',
            name: 'jayaccueil',
            component: () => import('@/views/accueil/Jayaccueil.vue'),
            meta: {
                layout: 'vertical',
                requiresAuth: true
            },
        },
        {
            path: '/accueil',
            name: 'accueil',
            component: () => import('@/views/accueil/Accueil.vue'),
            meta: {
                layout: 'vertical',
                requiresAuth: false
            },
        },
        {
            path: '/contactez-nous',
            name: 'contact-us',
            component: () => import('@/views/pages/ContactUs.vue'),
            meta: {
                layout: 'vertical',
                rule: 'editor'
            }
        },
        {
            path: '/local-authorities/admin',
            name: 'local-authorities-admin',
            component: () => import('@/views/local-authorities/LocalAuthorityAdministration.vue'),
            meta: {
                layout: 'vertical',
                requiresAuth: true,
                localAuthorityMember: true
            }
        },
        {
            path: '/local-authorities/admin/data',
            name: 'local-authorities-data',
            component: () => import('@/views/local-authorities/LocalAuthorityAdministrationData.vue'),
            meta: {
                layout: 'vertical',
                requiresAuth: true,
                localAuthorityMember: true
            }
        },
        {
            path: '/guide',
            name: 'guide',
            component: () => import('@/views/pages/Guide.vue'),
            meta: {
                layout: 'vertical',
            }
        },
        {
            path: '/a-propos',
            name: 'about-us',
            component: () => import('@/views/pages/AboutUs.vue'),
            meta: {
                layout: 'vertical',
            }
        },
        {
            path: '/pratiques/',
            name: 'knowledge-base',
            component: () => import('@/views/help-sheets/KnowledgeBase.vue'),
            meta: {
                layout: 'vertical',
                rule: 'editor',
                requiresAuth: true
            }
        },
        {
            path:'/fiches-pratiques/:sheetId',
            name: 'sheets',
            component: () => import('@/views/help-sheets/HelpSheetPage'),
            meta: {
                layout: 'vertical',
                rule: 'editor'
            }
        },
        {
            path: '/login',
            name: 'login',
            component: () => import('@/views/login/Login.vue'),
            meta: {
                layout: 'auth',
                noLoggedAccess: true
            },
        },
        {
            path: '/relogin',
            name: 'login-required',
            component: () => import('@/views/login/Login.vue'),
            meta: {
                layout: 'auth',
            },
        },
        {
            path: '/register',
            name: 'register',
            component: () => import('@/views/login/Register.vue'),
            meta: {
                layout: 'auth',
                noLoggedAccess: true
            }
        },

      //specific for CFP
        {
            path: '/pages/register',
            name: 'pages-register',
            component: () => import('@/views/login/Register.vue'),
            meta: {
                layout: 'auth',
                noLoggedAccess: true
            }
        },

        {
            path: '/pages/social-login',
            name: 'social-login',
            component: () => import('@/views/login/SocialLogin.vue'),
            meta: {
                layout: 'auth',
                noLoggedAccess: true
            }
        },
        {
            path: '/error-404',
            name: 'error-404',
            component: () => import('@/views/pages/Error404.vue'),
            meta: {
                layout: 'auth',
                noLoggedAccess: true
            }
        },

        // Redirect to 404 page, if no match found
        {
            path: '*',
            redirect: '/error-404'
        },

        {
            path: '/verify-user',
            name: 'verify-user',
            component: () => import('@/views/login/VerifyUser.vue'),
            meta: {
                layout: 'auth',
            }
        },
        {
            path: '/forgot-password',
            name: 'forgot-password',
            component: () => import('@/views/login/ForgotPassword.vue'),
            meta: {
                layout: 'auth',
            }
        },
        {
            path: '/pages/reset-password',
            name: 'reset-password',
            component: () => import('@/views/login/ResetPassword.vue'),
            meta: {
                layout: 'auth',
            }
        },
        {
            path: "/mentions-legales",
            name: "legal-notice",
            component: () => import("@/views/gdpr/GdprDocPage.vue"),
            meta: {
                layout: 'vertical',
            }
        },
        {
            path: "/conditions-utilisation",
            name: "general-conditions",
            component: () => import("@/views/gdpr/GdprDocPage.vue"),
            meta: {
                layout: 'vertical',
            }
        },
        {
            path: "/charte-protection-donnees",
            name: "gdpr-charter",
            component: () => import("@/views/gdpr/GdprDocPage.vue"),
            meta: {
                layout: 'vertical',
            }
        },
        {
            path: "/politique-cookies",
            name: "cookie-policy",
            component: () => import("@/views/gdpr/GdprDocPage.vue"),
            meta: {
                layout: 'vertical',
            }
        },
        {
            path: "/signature-doc-rgpd",
            name: "gdpr-doc-signature",
            component: () => import("@/views/gdpr/GdprDocSignature.vue"),
            meta: {
                layout: 'vertical',
            }
        },
        {
            path: "/vos-retours",
            name: "user-feedbacks",
            component: () => import("@/views/feedbacks/UsageFeedbacks.vue"),
            meta: {
                layout: 'vertical',
                requiresAuth: true
            }
        },
        {
            //todo: infinite loading due to beforeEach in router "this.$store" is undefined"
            path: '/update-required',
            name: 'update-required',
            component: () => import('@/views/login/UpdateRequired.vue'),
            meta: {
                layout: 'auth',
            }
        },
        {
            path: '/change-password',
            name: 'change-password',
            component: () => import('@/views/login/ChangePassword.vue'),
            meta: {
                layout: 'auth',
                requiresAuth: true
            }
        },
        {
            path: '/identifier-son-sol/',
            name: 'soil-identification-page',
            component: () => import('@/views/pages/SoilIdentificationPage.vue'),
            meta: {
                layout: 'vertical',
                rule: 'editor',
                requiresAuth: true
            }
        },
        {
            path: '/interactions/',
            name: 'interactions-explorer',
            component: () => import('@/views/vgarden/InteractionsExplorer.vue'),
            meta: {
                layout: 'vertical',
                rule: 'editor',
                requiresAuth: true
            }
        },
        {
            path: '/plants/:plantId',
            name: 'plants',
            component: () => import('@/views/plants/PlantPage'),
            meta: {
                layout: 'vertical',
                rule: 'editor'
            }
        },
        {
            path: '/animals/:animalId',
            name: 'animals',
            component: () => import('@/views/animals/AnimalPage'),
            meta: {
                layout: 'vertical',
                rule: 'editor'
            }
        },
        {
            path: '/notes',
            name: 'notes',
            component: () => import('@/components/notes/Notes.vue'),
            meta: {
                layout: 'vertical',
                rule: 'editor',
                requiresAuth: true
            }
        },
        {
            path: '/observations',
            name: 'observations',
            component: () => import('@/components/animals/Observations.vue'),
            meta: {
                layout: 'vertical',
                rule: 'editor',
                requiresAuth: true
            }
        },
        {
            path: '/gestion-jardin/:mode',
            name: 'manage-garden',
            component: () => import('@/views/jardins/ManageGarden.vue'),
            props: true,
            meta: {
                layout: 'vertical',
                rule: 'editor',
                requiresAuth: true
            }
        },
        {
            path: '/mon-compte',
            name: 'account',
            component: () => import('@/components/user/AccountSettings.vue'),
            meta: {
                layout: 'vertical',
                rule: 'editor',
                requiresAuth: true
            }
        },

        // =============================================================================
        // Potagers
        // =============================================================================
        {
            path: '/potager/:vgardenId',
            name: 'vgarden',
            component: () => import('@/views/vgarden/VGarden.vue'),
            meta: {
                layout: 'vertical',
                rule: 'editor',
                requiresAuth: true
            }
        },
        {
            path: '/potagers',
            name: 'vgardens',
            component: () => import('@/views/vgarden/VGardenList.vue'),
            meta: {
                layout: 'vertical',
                requiresAuth: true
            },
        },
        {
            path: '/potager-creation/',
            name: 'new-vgarden',
            component: () => import('@/views/vgarden/NewVGarden.vue'),
            meta: {
                layout: 'vertical',
                rule: 'editor',
                requiresAuth: true
            }
        },
    ],
})
router.beforeEach((to, from, next) => {
    if (to.name !== from.name) {
        if (from.name && to.name !== 'login-required') {
            router.app.$loading(true)
        }
        if (router.app.$options.store && to.name !== 'update-required' && to.name !== 'login-required') {
            let action = 'account/fetchProfile'
            if (from.name === null) {action = 'account/fetchData'}
            router.app.$options.store.dispatch(action)
                .then(() => {
                    router.app.$options.store.dispatch('gdpr/fetchGdprLastDocsNotSigned')
                        .then(() => {
                            router.app.$loading(false)
                            if (router.app.$options.store.state.gdpr.gdprLastDocsNotSigned.length > 0 &&
                                to.name !== "gdpr-doc-signature") {
                                router.app.$notify({
                                    time: 4000,
                                    title: 'Nouveaux documents',
                                    color: 'primary',
                                    text: "Une nouvelle version des documents légaux nécessite votre approbation",
                                })
                                if (router.currentRoute.name !== "gdpr-doc-signature") {
                                    router.push({name: 'gdpr-doc-signature'})
                                }
                            } else {
                                if (to.matched.some(record => record.meta.localAuthorityMember)
                                    && router.app.$options.store.state.localAuthority.memberOf == null) {
                                    next({name: 'local-authorities'})
                                }
                                // Must be logged ?
                                else if (to.matched.some(record => record.meta.noLoggedAccess)) {
                                    next({name: 'jayaccueil'})
                                }
                                // Ok
                                else {
                                    next()
                                }
                            }
                        })
                })
                .catch(error => {
                    router.app.$loading(false)
                    if (to.matched.some(record => record.meta.requiresAuth) && error === 'notLogged') {
                        next({name: 'login'})
                    } else if (error === 'serverError' && to.name !== 'login') {
                        router.app.$notify({
                            time: 4000,
                            title: 'Internet',
                            color: 'primary',
                            text: 'Veuillez vérifier votre connexion',
                        })
                        next({name: 'login'})
                    } else {
                        next()
                    }
                })
        } else {
            router.app.$loading(false)
            next()
        }
    } else {
        next()
    }

})
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach((to, from) => {
    // Remove initial loading
    if (from.name === null) {
        setTimeout(() => {
            const appLoading = document.getElementById('loading-bg')
            if (appLoading) {
                appLoading.style.display = 'none'
            }
        }, 1000);
    }

    router.app.$loading(false)
})
window.getVueRouter = () => {return router}
export default router
