export default {
    placeTypes: [
        {
            name: 'Autre',
            type: 'OT'
        },
        {
            name: 'Parc',
            type: 'PA',
        },
        {
            name: 'Forêt',
            type: 'FO',
        },
        {
            name: 'Compostage',
            type: 'CO',
        },
        {
            name: 'Décheterie',
            type: 'WA',
        },
        {
            name: 'Arbre',
            type: 'TR',
        },
        {
            name: 'Massif fleuri',
            type: 'FL',
        },
        {
            name: 'Musée',
            type: 'MU',
        },
        {
            name: "Cours d'eau",
            type: 'WC'
        },
        {
            name: "Point d'eau",
            type: 'WP'
        },
        {
            name: "Cimetière",
            type: 'CE'
        },
        {
            name: "Pépinière",
            type: 'CE'
        },
        {
            name: "Zone humide",
            type: 'WE'
        },
        {
            name: 'Verger',
            type: 'OR'
        },
        {
            name: 'Potager',
            type: 'VG'
        },

    ],
}
