/*=========================================================================================
  File Name: moduleHelpSheet.js
  Description: HelpSheet Module
  ----------------------------------------------------------------------------------------
  Item Name: JAYA - HelpSheet
  Author: JAYA SAS
  Author URL: https://jaya-HelpSheet.fr
==========================================================================================*/


import state from './moduleHelpSheetState.js'
import mutations from './moduleHelpSheetMutations.js'
import actions from './moduleHelpSheetActions.js'
import getters from './moduleHelpSheetGetters.js'

export default {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
