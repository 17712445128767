import axios from "@/axios.js"
import node_package from "../package.json"

/**
 * Log levels enumeration
 *
 * @type {Readonly<Object>}
 */
const LogLevel = Object.freeze({
    ERROR: 40,
    WARNING: 30,
    INFO: 20,
    DEBUG: 10,
})

/**
 * Default logger settings
 *
 * @type {Object}
 */
let loggerSettings = {
    level: LogLevel.INFO, // Minimum log level to send
    waitBeforeSending: 1000, // Time to wait (in milliseconds) before sending request in order to aggregate logs
    sendingLogsPeriod: 6000, // Minimum time between each sending of logs in milliseconds
    maxLogsPerRequests: 10, // Maximum number of logs per requests (other logs are deleted)
    maxFullRequestsCount: 3, // Number of full requests in a row before stopping sending log (burst detection)
    maxSendingErrorsCount: 3, // Number of sending errors in a row before stopping sending log (backend error detection)
    sendLogs: false, // Should send logs to the backend?
}

// Retrieve settings from the backend
axios.get('jayascript_log/')
    .then(response => {
        loggerSettings = response.data
        isNextSendScheduled = false
        updateLogsSender()
    })
    .catch(error => {
        loggerSettings.sendLogs = false
        logger.error("Fail to retrieve log settings\n" + error.stack)
    })

/**
 * Count the number of full requests in a row for burst detection
 *
 * @type {number}
 */
let fullRequestsCount = 0

/**
 * Count the number of sending logs errors in a row for backend error detection
 *
 * @type {number}
 */
let sendingErrorsCount = 0

/**
 * The logs buffer
 *
 * @type {Object[]}
 */
let logsBuffer = []

/**
 * Are we currently sending logs?
 *
 * @type {boolean}
 */
let isSendingLogs = false

/**
 * The last time we try to send a log
 *
 * @type {number}
 */
let lastSendLogsAttempt = 0

/**
 * The next send is scheduled?
 *
 * @type {boolean}
 */
let isNextSendScheduled = false

/**
 * Manage log sender scheduling
 */
function updateLogsSender() {

    // Check if there is no scheduled sending
    if (!isNextSendScheduled) {
        let currentTime = new Date().getTime()

        // Check time requirements
        if (!isSendingLogs && currentTime - lastSendLogsAttempt > loggerSettings.sendingLogsPeriod) {
            isSendingLogs = true
            lastSendLogsAttempt = currentTime

            // Wait a little before sending to aggregate logs
            setTimeout(() => {
                if (navigator.onLine && loggerSettings.sendLogs) {
                    sendLogsBuffer()
                        .catch(error => {
                            logger.error("Fail to send logs\n" + error.stack)
                        })
                        .finally(() => {
                            isSendingLogs = false
                        })
                }
            }, loggerSettings.waitBeforeSending)

        } else {

            // Schedule next sending attempt
            isNextSendScheduled = true
            let nextAttemptTime = 100 + Math.max(0, loggerSettings.sendingLogsPeriod - (currentTime - lastSendLogsAttempt))
            setTimeout(() => {
                isNextSendScheduled = false
                updateLogsSender()
            }, nextAttemptTime)
        }
    }
}

/**
 * Try to send logs buffer in the backend and manage the buffer accordingly
 *
 * @returns {Promise} the response
 */
function sendLogsBuffer() {

    // Clone and clean the buffer for better concurrency management.
    let logs = [...logsBuffer]
    logsBuffer = []

    // Burst detection
    if (logs.length >= loggerSettings.maxLogsPerRequests) {
        fullRequestsCount++

        // Keep only the last "maxLogsPerRequests" logs
        logs = logs.slice(logs.length - loggerSettings.maxLogsPerRequests)
    } else {
        fullRequestsCount = 0
    }

    // Payload
    let payload = {
        app_version: node_package.version,
        logs: logs
    }
    if (fullRequestsCount >= loggerSettings.maxFullRequestsCount) {
        // Notify a burst detection
        payload['burstDetected'] = true
        logger.warn("Too many logs detected")
        loggerSettings.sendLogs = false
    }

    // Send logs
    return new Promise((resolve, reject) => {
        axios.post('jayascript_log/', payload)
            .then(response => {
                sendingErrorsCount = 0
                resolve(response)
            })
            .catch(error => {
                sendingErrorsCount++
                if (sendingErrorsCount >= loggerSettings.maxSendingErrorsCount) {
                    logger.warn("Maximum number of send logs attempts reached")
                    loggerSettings.sendLogs = false
                }
                // Re-inject logs if we can't send log to the backend
                logsBuffer = [...logs, ...logsBuffer]
                reject(error)
            })
    })
}

/**
 * Logger class
 */
class Logger {

    loggerName = "default"

    /**
     * Create a new logger
     *
     * @param loggerName the logger name
     */
    constructor(loggerName) {
        this.loggerName = loggerName;
    }

    /**
     * Aggregate logs in a common buffer and manage time handler
     *
     * @param levelId the log level number
     * @param message the log message
     * @param extra extra log information
     */
    log(levelId, message, extra) {

        // Burst detection: we stop managing logs if we detect a burst
        if (fullRequestsCount >= loggerSettings.maxFullRequestsCount) {
            return
        }

        // Check log level
        if (levelId < loggerSettings.level) {
            return
        }

        // Get log level name
        let levelName = Object.entries(LogLevel).find(level => level[1] === levelId)
        if (levelName !== undefined) {
            levelName = levelName[0]
        } else {
            // Invalid log level
            return
        }

        // Check buffer size
        if (logsBuffer.length > loggerSettings.maxLogsPerRequests) {
            logsBuffer.shift() // Delete oldest log
        }

        // Generate log object and push it in the buffer
        let log = {
            logger_name: this.loggerName,
            level: levelName,
            timestamp: new Date().getTime(),
            message: message,
            location_href: window.location.href,
            location_pathname: window.location.pathname,
            extra: extra
        }
        logsBuffer.push(log)

        // Update the log sender scheduler if we want to send logs to the backend
        if (navigator.onLine && loggerSettings.sendLogs) {
            updateLogsSender()
        }
    }

    /*
     * Basic log functions:
     */

    error(message, extra = {}) {
        if (process.env.NODE_ENV === 'development') {
            console.error("[" + this.loggerName + "] " + message)
        }
        this.log(LogLevel.ERROR, message, extra);
    }

    warn(message, extra = {}) {
        if (process.env.NODE_ENV === 'development') {
            console.warn("[" + this.loggerName + "] " + message)
        }
        this.log(LogLevel.WARNING, message, extra);
    }

    info(message, extra = {}) {
        if (process.env.NODE_ENV === 'development') {
            console.info("[" + this.loggerName + "] " + message)
        }
        this.log(LogLevel.INFO, message, extra);
    }

    debug(message, extra = {}) {
        if (process.env.NODE_ENV === 'development') {
            console.debug("[" + this.loggerName + "] " + message)
        }
        this.log(LogLevel.DEBUG, message, extra);
    }
}

/**
 * The "logger of the logger"
 */
let logger = new Logger("logger")

export default {
    getLogger(name) {
        return new Logger(name)
    }
}