import state from './modulePlaceState.js'
import mutations from './modulePlaceMutations.js'
import actions from './modulePlaceActions.js'
import getters from './modulePlaceGetters.js'

export default {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
