import axios from '@/axios.js'

export default {
  fetchNotes({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.get('/global_notes/', {params: payload})
        .then(response => {
          if(!response.data.previous)
            commit('CLEAR_NOTES')
          commit('ADD_NOTES', response.data.results)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getNote(context, payload) {
    return new Promise((resolve, reject) => {
      axios.get('/notes/' + payload + '/')
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  addNote({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/notes/', payload)
        .then(response => {
          commit('ADD_NOTE', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  editNote({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.patch('/notes/' + payload.id + '/', payload)
        .then(response => {
          commit('EDIT_NOTE', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  deleteNote({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.delete('/notes/' + payload.id +'/')
        .then(response => {
          commit('REMOVE_NOTE', payload)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  addReminder({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/reminders/', payload)
        .then(response => {
          commit('ADD_REMINDER', response.data)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  editReminder({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.patch('/reminders/' + payload.reminderId + '/', payload)
        .then(response => {
          commit('ADD_REMINDER', response.data)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  deleteReminder({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.delete('/reminders/' + payload.id +'/')
        .then(response => {
          commit('REMOVE_REMINDER', payload.note)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
}
