/*=========================================================================================
  File Name: moduleCalendarState.js
  Description: Calendar Module State
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {

    // Calendar Labels
    taskLabels: [
        {
            text: "arroser",
            color: "primary",
            value: "AR"
        },
        {
            text: "tailler",
            color: "warning",
            value: "TA"
        },
        {
            text: "récolter",
            color: "danger",
            value: "RE"
        }
    ],

    // Simple Calendar State
    tasks: [],

    //Colors according to the Labels
    colors: [
        {
            label: "AR",
            color: "success"
        },
        {
            label: "TA",
            color: "warning"
        },
        {
            label: "RE",
            color: "danger"
        },
        {
            label: "AU",
            color: "primary"
        }
    ]
}
