export default {

    plants: [],
    filters: {
        category: [],
        shade: [],
        duration: [],
        fertility: [],
        beneficEffect: [],
        concernedSpicie: [],
        flowerColor: []
    },
    // for other filter values
    temperature: 0,
    local: false,
    balcony: false,
    onBoardingStep: 0,
}
