export default {

    SET_EVENTS(state, localAuthority) {
        state.subscription = localAuthority
    },

    SET_MEMBER_OF(state, localAuthority) {
        state.memberOf = localAuthority
    },
}
