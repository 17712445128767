<template>
  <div
      id="app"
      :class="[skinClasses]"
      class="h-100"
  >
    <b-overlay
        :show="$store.state.app.load || onBoardingRunning"
        no-center
        rounded="sm"
        style="min-height: 100vh;"
        z-index="1000"
        :class="onBoardingRunning ? 'onboarding-overlay' : ''"
    >
      <div id="navbar-jaya" style="height: 1px; width: 1px; position: absolute; left: 100px; top:350px"></div>
      <component :is="layout">
        <router-view/>
      </component>

      <CookieMessage />

      <template #overlay>
        <div
            class="position-fixed d-flex justify-content-center align-items-center"
            style="top: 0; right: 0; height: 100vh; width: 100vw"
        >
          <div class="d-flex flex-column align-items-center">
            <b-spinner
                style="width: 6rem!important; height: 6rem!important;"
                variant="primary"
                v-if="$store.state.app.load"
            />
            <div
                v-if="$store.state.app.message"
                class="text-primary text-center mt-1 font-medium-3 max-w-xs"
            >
              {{ $store.state.app.message }}
            </div>
          </div>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
// This will be populated in `beforeCreate` hook
import {$themeBreakpoints, $themeConfig} from '@themeConfig'
import {provideToast} from 'vue-toastification/composition'
import {watch} from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import {BOverlay, BSpinner} from 'bootstrap-vue'
import node_package from "../package.json"
import {useCssVar, useWindowSize} from '@vueuse/core'
import {pauseDifferentOnBoarding} from "@/globalMethods";

import store from '@/store'
import CookieMessage from "@/views/gdpr/GdprCookieMessage";

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')
const LayoutAuth = () => import('@/layouts/auth/LayoutAuth.vue')

export default {
  components: {
    // Layouts
    LayoutVertical,
    LayoutFull,
    LayoutAuth,
    BOverlay,
    BSpinner,
    CookieMessage,
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === 'vertical') {
        return 'layout-vertical'
      }
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
    onBoardingRunning() {
      const onBoardingState = this.$store.state.account.onBoardingState
      return onBoardingState.encyclopedia.currentState > 0 || onBoardingState.home.currentState > 0
          || onBoardingState.garden.currentState > 0
    }
  },
  beforeCreate() {
    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']
    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement)
          .value
          .slice(0, -2))
    }

    // Set RTL
    const {isRTL} = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')

    if (process.env.VUE_APP_USE_TOKEN) {
      window.handleOpenURL = url => {
        // This regex remove the scheme when it is necessary
        this.$router.push('/' + url.replace(/^[^:]*:\/*/, ''))
      }
    }
  },
  mounted() {
    window.getRoute = () => {
      return this.$route
    }
    const this_ = this;
    const manageFCM = function() {
      const cordova = window.cordova
      cordova.plugins.firebase.messaging.requestPermission({forceShow: true}).then(function () {
        cordova.plugins.firebase.messaging.getToken().then(function (token) {
          this_.$store.commit("account/UPDATE_FIREBASE_TOKEN", token)
          if (this_.$store.state.account.user && this_.$store.state.account.firebaseToken.id === null) {
            this_.$store.dispatch("notifications/fetchDevices")
          }
        });
      });
      //todo create channels for plants, forum, notes ect...
      cordova.plugins.firebase.messaging.onMessage(function (payload) {
        payload.firebase = "Firebase"
        const evt = new Event("newnotification", {"bubbles": true, "cancelable": false});
        document.dispatchEvent(evt)
        if (cordova.platformId === "android") {
          this_.$notify({
            time: 4000,
            title: payload.gcm.title,
            text: payload.gcm.body,
            color: "primary"
          })
        } else {
          this_.$notify({
            time: 4000,
            title: payload.alert.aps.title,
            text: payload.alert.aps.body,
            color: "primary"
          })
        }

      });
      cordova.plugins.firebase.messaging.onBackgroundMessage(function (payload) {
        console.log("New background FCM message: ", payload);
      });
    }
    this_.$store.dispatch("notifications/fetchDevices")
    if (process.env.VUE_APP_USE_TOKEN) {
      document.addEventListener("deviceready", () => {
        manageFCM()
      }, false);
      document.addEventListener("logindone", () => {
        manageFCM()
      });
      document.addEventListener("deviceready", () => {
        manageFCM();
        document.addEventListener("resume", () => {
          const evt = new Event("newnotification", {"bubbles": true, "cancelable": false});
          document.dispatchEvent(evt)
        }, false);
      }, false);
    }
    this.$store.dispatch('version/getVersion')
        .then((response) => {
          if (window.cordova && node_package.version && response.data[0].minimum_version > node_package.version) {
            this.$router.push({name: 'update-required'})
          }
        })
  },
  setup() {
    const {
      skin,
      skinClasses
    } = useAppConfig()

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const {width: windowWidth} = useWindowSize()
    watch(windowWidth, val => {
      const pausedOnboardings = pauseDifferentOnBoarding("", store)
      setTimeout(() => {
        for (const pausedOnboarding of pausedOnboardings) {
          store.commit("account/START_ONBOARDING", pausedOnboarding)
        }
      }, 100)
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
    }
  },
}
</script>
<style lang="scss">
@font-face {
  font-family: 'Hortypo';
  src: url('./assets/fonts/Hortypo.ttf');
}
</style>

