import axios from "@/axios.js"

export default {
    getQuiz({commit}, params) {
        return new Promise((resolve, reject) => {
            axios.get('/api/quizzes/', {params: params})
                .then(response => {
                    if (response.data[0].type == 'CF') {
                        commit("SET_QUIZZ_CDF", response.data[0])
                    }
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    answerQuiz({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post('api/quiz_answers/', payload)
                .then(response => {
                    commit("SET_ANSWERS_CDF", response.data)
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    updateAnswerQuiz({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.patch('api/quiz_answers/' + payload.quizAnswerer.toString() + '/', payload)
                .then(response => {
                    commit("SET_ANSWERS_CDF", response.data)
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    getQuizAnswers({commit}) {
        return new Promise((resolve, reject) => {
            axios.get('api/quiz_answers/', {params: {type: "CF"}})
                .then(response => {
                    commit("SET_ANSWERS_CDF", response.data[0])
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    getScore(context, params) {
        return new Promise((resolve, reject) => {
            axios.get('api/score/', {params: params})
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    getUsageFeedBacks({commit}, params) {
        return new Promise((resolve, reject) => {
            axios.get('api/usage_feedback/', {params: params})
                .then(response => {
                    commit("SET_FEEDBACKS", response.data)
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    createUsageFeedBacks({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post('api/usage_feedback/', payload)
                .then(response => {
                    commit("APPEND_FEEDBACKS", response.data)
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    updateUsageFeedBacks({commit}, payload) {
        return new Promise((resolve, reject) => {
            const usageFeedbackId = payload.id
            axios.patch('api/usage_feedback/' + usageFeedbackId.toString() + "/", payload)
                .then(response => {
                    commit("UPDATE_FEEDBACK", response.data)
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    deleteUsageFeedBacks({commit}, payload) {
        return new Promise((resolve, reject) => {
            const usageFeedbackId = payload.id
            axios.delete('api/usage_feedback/' + usageFeedbackId.toString())
                .then(response => {
                    commit("DELETE_FEEDBACK", payload.id)
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
}
