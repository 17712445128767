<template>
  <div ref="convstooltip" class="jaya-tool-tip">
    <b-button
        :id="'popover-tooltip-' + key"
        v-ripple.400="'var(--info-light)'"
        class="p-0"
        type="button"
        variant="flat-info">
      <div class="text-visual">
        <slot></slot>
      </div>
    </b-button>

    <b-popover
        :target="'popover-tooltip-' + key"
        :variant="color"
        placement="auto"
        triggers="hover focus">
      <template #title>
        <span> {{ title }} </span>
      </template>
      <div class="text-justify">
        <slot name="text"></slot>
        <span> {{ text }} </span>
      </div>
    </b-popover>
  </div>
</template>

<script>
import {BButton, BPopover, VBPopover} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  name: "JayaTooltip",
  components: {
    BButton,
    BPopover
  },
  directives: {
    'b-popover': VBPopover,
    Ripple
  },
  props: {
    title: {
      type: [String, Number],
      default: "En savoir plus"
    },
    text: {
      type: [String, Number],
      default: null
    },
    color: {
      type: String,
      default: 'info'
    },
  },
  computed: {
    key() {
      return this._uid
    },
  }
}
</script>

<style lang="scss">
.jaya-tool-tip {
  display: inline;
}
</style>


