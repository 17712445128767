import axios from "@/axios.js"

export default {
    getVersion() {
        return new Promise(((resolve, reject) => {
            axios.get("/minimum_version/")
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        }))
    }
}
