export default {
  gdprDocTypes : [
    {
      choice: 1,
      name: "legal-notice",
    },
    {
      choice: 2,
      name: "general-conditions",
    },
    {
      choice: 3,
      name: "gdpr-charter",
    },
    {
      choice: 4,
      name: "cookie-policy",
    },
  ],
  gdprLastDocs: [],
  gdprLastDocsNotSigned: [],
}
