export default {

    SET_SUBSCRIPTIONS(state, localAuthorities) {
        state.subscriptions = localAuthorities
    },

    SET_MEMBER_OF(state, localAuthority) {
        state.memberOf = localAuthority
    },

    SET_COLOR(state, colors) {
        state.colors = colors
    },

    SET_TRANSPARENT_HEADER(state, transparency) {
        state.transparent_header = transparency
    }
}
