export default {
    posts: [
        {
            id: 0,
            title: "Comment se débarasser des limaces ?",
            description: "",
            tags: ["nuisibles", "limaces"],
            date: "aujourd'hui",
            user: "Marie",
            userId: 0,
            chatLog: [
                {
                    user: 'Yves',
                    message: 'Parmi les remèdes classiques du bio, la cendre est citée, mais il est souvent indiquée qu’elle n’est plus efficace dès que la pluie la mouille. En outre, l’excès de cendre est dangereux pour le sol. Les coquilles d’oeufs écrasées sont utilisées autour des plantes à protéger, mais certains doutent de leur efficacité. Parmi les différents paillis rugueux, sont citées les fougères aigle fraîches grossièrement broyées et les aiguilles de pin, qui semblent dissuasives.',
                    date: 'aujourd\'hui à 15h'
                },
                {
                    user: 'Marie',
                    message: 'D\'accord merci du conseil !',
                    date: 'Il y à deux minutes'
                },
                {
                    user: 'Geraldine',
                    message: 'Je me peremet de proposer aussi l\'utilisation d\'auxilères. En créant des habitats pour les prédateurs des limaces, on peut limiter leur population. ça à très bien marché chez moi. ',
                    date: 'à l\'instant'
                },
            ],
        },
        {
            id: 1,
            title: "Dois-je tailler mon rosier ?",
            description: "",
            tags: ["roses", "taille", "fleur"],
            date: "10/04/2020",
            user: "Jean-baptiste",
            userId: 1,
            chatLog: []
        },
        {
            id: 2,
            title: "Comment enlever le mildiou de mes tomates ?",
            description: "",
            tags: ["maladie", "tomates", "mildiou", "potager"],
            date: "27/04/2020",
            user: "Bertrand",
            userId: 2,
            chatLog: []
        },
        {
            id: 3,
            title: "Est-il possible de protéger mes plantes du gel ?",
            description: "",
            tags: ["gel"],
            date: "24/05/2020",
            user: "Beatrice",
            userId: 3,
            chatLog: []
        },
    ]
}
