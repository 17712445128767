import axios from "@/axios.js"

export default {
    getQuestions(context, params) {
        return new Promise((resolve, reject) => {
            axios.get('api/posts/', {params: params})
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    postQuestion(context, params) {
        return new Promise((resolve, reject) => {
            axios.post('api/posts/', params)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    getPost(context, id) {
        let url = 'api/posts/' + id.toString() + '/'
        return new Promise((resolve, reject) => {
            axios.get(url)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    updatePost(context, params) {
        let url = 'api/posts/' + params.id.toString() + '/'
        return new Promise((resolve, reject) => {
            axios.patch(url, params)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    deleteQuestion(context, id) {
        let url = 'api/posts/' + id.toString() + '/'
        return new Promise((resolve, reject) => {
            axios.delete(url)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    postAnswer(context, params) {
        return new Promise((resolve, reject) => {
            axios.post('api/post_answers/', params)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    getAnswers(context, params) {
        return new Promise((resolve, reject) => {
            axios.get('api/post_answers/', {params: params})
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    deleteAnswer(context, id) {
        return new Promise((resolve, reject) => {
            let url = 'api/post_answers/' + id.toString() + '/'
            axios.delete(url)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    // Reporting
    report(context, params) {
        return new Promise((resolve, reject) => {
            axios.post('api/report/', params)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }

}
