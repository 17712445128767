export default {
    user: null,
    registerStatus: null,
    userIsInactive: false,
    firebaseToken: {
        value: null,
        id: null
    },
    onBoarding: null,
    onBoardingState : {
        garden: {currentState: 0, maxState:1, paused:true, savedState:1},
        encyclopedia: {currentState: 0, maxState:4, paused:true, savedState:1},
        home : {currentState: 0, maxState:7, paused:true, savedState:1}
    }
}
