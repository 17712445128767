export default {
    SET_GDPR_LAST_DOCS(state, payload) {
        payload.forEach((payloadEl) => {
            if (
                !state.gdprLastDocs.some(
                    (gdprLastDoc) => gdprLastDoc.doc_type === payloadEl.doc_type
                )
            ) {
                state.gdprLastDocs.push(payloadEl);
            }
        });
    },
    SET_GDPR_LAST_DOCS_NOT_SIGNED(state, payload) {
        state.gdprLastDocsNotSigned = payload
    },
}
