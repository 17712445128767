import axios from "@/axios.js"

function parseEvent(event) {

    // Convert string dates to javascript Date object
    if (event.start_date != null) {
        event.start_date = new Date(event.start_date)
    }
    if (event.end_date != null) {
        event.end_date = new Date(event.end_date)
    }

    // Convert location point to [longitude, latitude] array
    if (event.location_point != null) {
        let location_type_start = "SRID=4326;POINT ("
        if (event.location_point.startsWith(location_type_start)) {
            let from = location_type_start.length
            let length = event.location_point.length - from - 1
            event.location_point = event.location_point.substr(from, length).split(' ')
        } else {
            // Incompatible location
            event.location_point = null
        }
    }
    return event
}

export default {

    /*
     * SEARCH
     */

    fetchEvent(context, id) {
        return new Promise((resolve, reject) => {
            axios.get("events/" + id + "/")
                .then(response => {
                    resolve(parseEvent(response.data))
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    fetchEvents(context, payload) {
        return new Promise((resolve, reject) => {
            /* The payload contains the pagination stuff (offset and limit)
             * And the filtering stuff:
             *  - local_authority
             *  - subscription
             *  - before_date
             *  - after_date
             *  - ordering
             */
            axios.get("events/", {params: payload})
                .then(response => {
                    let data = response.data
                    data.results = response.data.results.map(event => parseEvent(event))
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    /*
     * Subscription
     */

    subscribe(context, eventId) {
        return new Promise((resolve, reject) => {
            axios.post("events/" + eventId + "/subscription/")
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    unsubscribe(context, eventId) {
        return new Promise((resolve, reject) => {
            axios.delete("events/" + eventId + "/subscription/")
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    /*
     * Administration
     */

    createEvent(context, event) {
        return new Promise((resolve, reject) => {
            axios.post("events/", event)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    updateEvent(context, event) {
        return new Promise((resolve, reject) => {
            axios.patch("events/" + event.id + "/", event)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    deleteEvent(context, eventId) {
        return new Promise((resolve, reject) => {
            axios.delete("events/" + eventId + "/")
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    addPartner(context, payload) {
        return new Promise((resolve, reject) => {
            let partner_obj = {
                local_authority: payload['local_authority']
            }
            axios.post("events/" + payload['id'] + "/partner/", partner_obj)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    deletePartner(context, payload) {
        return new Promise((resolve, reject) => {
            let partner_obj = {
                local_authority: payload['local_authority']
            }
            axios.delete("events/" + payload['id'] + "/partner/", {data: partner_obj})
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
}
