export default {
    SET_UPDATE_BIODIV_SCORE(state, value) {
        state.updateBiodivScore = value
    },
    SET_QUIZZ_CDF(state, value) {
        state.cdfQuiz = value
    },
    SET_ANSWERS_CDF(state, value) {
        state.cdfAnswers = value
    },
    SET_FEEDBACKS(state, value) {
        state.usageFeedbacks = value
    },
    APPEND_FEEDBACKS(state, value) {
        state.usageFeedbacks.push(value)
    },
    UPDATE_FEEDBACK(state, feedback) {
        const feedbackIndex = state.usageFeedbacks.findIndex((e) => e.id === feedback.id)
        if (feedbackIndex) {
            state.usageFeedbacks[feedbackIndex] = feedback
            state.usageFeedbacks.push([])
            state.usageFeedbacks.pop()
        }
    },
    DELETE_FEEDBACK(state, feedbackId) {
         state.usageFeedbacks = state.usageFeedbacks.filter((e) => e.id !== feedbackId)
    }
}
