import state from './modulePlantIdentificationState.js'
import mutations from './modulePlantIdentificationMutations.js'
import actions from './modulePlantIdentificationActions.js'
import getters from './modulePlantIdentificationGetters.js'

export default {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
