import axios from "@/axios.js"

function parsePlace(place) {

    // Convert location point to [longitude, latitude] array
    if (place.location_point != null) {
        let location_type_start = "SRID=4326;POINT ("
        if (place.location_point.startsWith(location_type_start)) {
            let from = location_type_start.length
            let length = place.location_point.length - from - 1
            place.location_point = place.location_point.substr(from, length).split(' ')
        } else {
            // Incompatible location
            place.location_point = null
        }
    }
    return place
}

export default {

    /*
     * SEARCH
     */

    fetchPlace(context, id) {
        return new Promise((resolve, reject) => {
            axios.get("places/" + id + "/")
                .then(response => {
                    resolve(parsePlace(response.data))
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    fetchPlaces(context, payload) {
        return new Promise((resolve, reject) => {
            /* The payload contains the pagination stuff (offset and limit)
             * And the filtering stuff:
             *  - local_authority
             *  - type
             */
            axios.get("places/", {params: payload})
                .then(response => {
                    let data = response.data
                    data.results = response.data.results.map(place => parsePlace(place))
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    /*
     * Administration
     */

    createPlace(context, place) {
        return new Promise((resolve, reject) => {
            axios.post("places/", place)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    updatePlace(context, place) {
        return new Promise((resolve, reject) => {
            axios.patch("places/" + place.id + "/", place)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    deletePlace(context, placeId) {
        return new Promise((resolve, reject) => {
            axios.delete("places/" + placeId + "/")
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    addPartner(context, payload) {
        return new Promise((resolve, reject) => {
            let partner_obj = {
                local_authority: payload['local_authority']
            }
            axios.post("places/" + payload['id'] + "/partner/", partner_obj)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    deletePartner(context, payload) {
        return new Promise((resolve, reject) => {
            let partner_obj = {
                local_authority: payload['local_authority']
            }
            axios.delete("places/" + payload['id'] + "/partner/", {data: partner_obj})
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
}
