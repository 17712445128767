import state from './moduleAnimalState.js'
import mutations from './moduleAnimalMutations.js'
import actions from './moduleAnimalActions.js'
import getters from './moduleAnimalGetters.js'

export default {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
