/*=========================================================================================
  File Name: moduleGarden.js
  Description: Garden Module
  ----------------------------------------------------------------------------------------
  Item Name: JAYA - GARDEN
  Author: JAYA SAS
  Author URL: https://jaya-garden.fr
==========================================================================================*/


import state from './moduleGardenState.js'
import mutations from './moduleGardenMutations.js'
import actions from './moduleGardenActions.js'
import getters from './moduleGardenGetters.js'

export default {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
