export default {
    // Updates user info in state and localstorage
    UPDATE_USER_INFO(state, payload) {
        state.user = payload;
    },
    UPDATE_LOGIN_STATUS(state, val) {
        state.loginStatus = val;
    },
    UPDATE_REGISTER_STATUS(state, val) {
        state.registerStatus = val;
    },
    UPDATE_ACTIVE_STATUS(state, val) {
        state.userIsInactive = val;
    },
    UPDATE_FIREBASE_TOKEN(state, val) {
        state.firebaseToken.value = val;
    },
    SET_FIREBASE_TOKEN_ID(state, val) {
        state.firebaseToken.id = val;
    },
    // Updates user onboarding in state
    UPDATE_USER_ONBOARDING(state, payload) {
        state.onBoarding = payload;
    },
    START_ONBOARDING(state, componentName) {
        if (!state.onBoarding[componentName]) {
            state.onBoardingState[componentName].currentState = state.onBoardingState[componentName].savedState
            state.onBoardingState[componentName].paused = false
        }
    },
    INCREMENT_ONBOARDING_STATE(state, componentName) {
        const currentState = state.onBoardingState[componentName].currentState
        state.onBoardingState[componentName].currentState = 0
        state.onBoardingState[componentName].currentState=currentState+1
    },
    PAUSE_ONBOARDING(state, componentName) {
        state.onBoardingState[componentName].paused = true
        state.onBoardingState[componentName].savedState = state.onBoardingState[componentName].currentState
        state.onBoardingState[componentName].currentState = 0
    },
    STOP_ONBOARDING(state, componentName) {
        state.onBoardingState[componentName].currentState = 0
    }
}
