import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import moduleAccount from './account/moduleAccount.js'
import moduleCalendar from './calendar/moduleCalendar.js'
import moduleGarden from './garden/moduleGarden.js'
import moduleEvent from './event/moduleEvent.js'
import modulePlace from './place/modulePlace.js'
import modulePlant from './plant/modulePlant.js'
import moduleLocalAuthority from './localAuthority/moduleLocalAuthority.js'
import moduleForum from './forum/moduleForum.js'
import moduleFeedback from './feedback/moduleFeedback.js'
import moduleHelpSheet from './helpSheet/moduleHelpSheet.js'
import moduleNotes from './notes/moduleNotes.js'
import moduleNotification from './notifications/moduleNotification.js'
import moduleZipCode from './zipCode/moduleZipCode.js'
import moduleVersion from './version/moduleVersion.js'
import modulePlantIdentification from './plantIdentification/modulePlantIdentification.js'
import moduleAnimal from './animal/moduleAnimal.js'
import moduleGDPR from './gdpr/moduleGDPR.js'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        app,
        appConfig,
        verticalMenu,
        account: moduleAccount,
        calendar: moduleCalendar,
        garden: moduleGarden,
        event: moduleEvent,
        place: modulePlace,
        plant: modulePlant,
        localAuthority: moduleLocalAuthority,
        forum: moduleForum,
        feedback: moduleFeedback,
        helpSheet: moduleHelpSheet,
        notes: moduleNotes,
        notifications: moduleNotification,
        zipCode: moduleZipCode,
        version: moduleVersion,
        plantIdentification: modulePlantIdentification,
        animal: moduleAnimal,
        gdpr: moduleGDPR,
    },

    mutations: {
        DELETE_ALL_USER_DATA(state) {
            state.account.user = null
            state.calendar.tasks = []
            state.garden.gardens = []
            state.notes.notes = []
            state.localAuthority.subscriptions = null
            state.localAuthority.memberOf = null
        },
    },

    strict: process.env.DEV,
})
