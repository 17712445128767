import state from './modulePlantState.js'
import mutations from './modulePlantMutations.js'
import actions from './modulePlantActions.js'
import getters from './modulePlantGetters.js'

export default {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
