<template>
  <div :key="gotAnimal ? animal.id : 0" class="animal-info" @click="clickx">
    <!-- Photo de garde -->
    <div v-if="gotAnimal" class="jaya-top-row p-1 mb-1">
      <swiper v-if="animalPhotos.length > 1" ref="swiper" :options="animalImageSwiperOption">
        <swiper-slide v-for="(photo, index) in animalPhotos" :key="index" class="d-flex justify-content-center w-100">
          <viewer ref="viewer" :images="[photo]" class="jaya-container">
            <img v-for="src in [photo]"
                :key="src"
                :alt="getAlt(animal.photos[index].author, animal.photos[index].license)"
                :src="src"
                class="jaya-image">
          </viewer>
        </swiper-slide>
        <div slot="pagination" class="swiper-pagination top-0"/>
      </swiper>
      <viewer v-else :images="animalPhotos" class="jaya-container">
        <img v-for="src in animalPhotos"
            :key="src"
            :alt="getAlt(animal.photos[0] ? animal.photos[0].author : null, animal.photos[0] ? animal.photos[0].license : null)"
            :src="src"
            class="jaya-image">
      </viewer>

      <div class="jaya-image-buttons">
        <b-button
            v-if="!animalPage"
            class="ml-1"
            variant="primary"
            @click="$refs.popUpAnimalObservation.show()">
          Je l'ai vu !
        </b-button>
        <b-button
            class="ml-1 p-50"
            pill
            size="sm"
            variant="primary"
            @click="copyLink">
          <feather-icon
              icon="Link2Icon"
              size="24"/>
        </b-button>
      </div>

      <div class="jaya-image-edit-buttons">
        <b-button
            v-if="animalPhotos.length > 1"
            class="ml-50 p-50"
            pill
            size="sm"
            variant="flat-primary"
            @click="showImageViewer">
          <feather-icon
              icon="MaximizeIcon"
              size="24"/>
        </b-button>
      </div>
    </div>

    <div v-if="gotAnimal">
      <b-row class="mb-1">
        <div class="ml-1">
          <span class="font-medium-5 font-weight-bolder"> {{ animal.common_name }} </span><br>
          <i class="font-medium-2"> {{ animal.infos_taxon.lb_nom }} </i> <br>
        </div>
      </b-row>

      <div class="w-100">
        <p class="text-justify ">
          <strong>Le saviez-vous ?</strong><br>
          {{ animal.anecdote }}. {{ animal.name_explanation }}.
        </p>
        <p class="text-justify " v-if="displaySynonyms(animal.synonyms)">
          <strong>Aussi connu sous le nom : </strong>
          <em v-for="(synonym, id) in animal.synonyms" :key="id">
            {{synonym}}{{id === animal.synonyms.length - 1 ? '' : ' - '}}
          </em>
        </p>
        <TextBlock class="mb-1" collapsable title="Caractéristiques">
          <feather-icon
              slot="titleSpace"
              icon="SlidersIcon"
              size="24"/>
          <p class="text-justify mt-1">
            A l'âge adulte, <em>{{ animal.infos_taxon.lb_nom }}</em>  a généralement les caractéristiques physiques
            suivantes
            <JayaTooltip title="Caractéristiques physiques">
              <feather-icon
                  class="mr-25"
                  icon="HelpCircleIcon"/>
              <template #text>
                Ces valeurs sont des moyennes : elles recouvrent des individus différents selon leur habitat, leur âge, leur sexe...
              </template>
            </JayaTooltip>:
          </p>
          <b-list-group>
            <b-list-group-item v-if="animal.size !== null && animal.size>0">
              <strong>Taille</strong> : {{ parseSize(animal.size)}}
            </b-list-group-item>
            <b-list-group-item v-if="animal.weight !== null && animal.weight>0"><strong>Poids</strong> : {{ parseWeight(animal.weight)}}</b-list-group-item>
            <b-list-group-item v-if="animal.width !== null && animal.width>0"><strong>Largeur (ou envergure)</strong> : {{ parseSize(animal.width) }} </b-list-group-item>
            <b-list-group-item v-if="animal.color_detail && animal.colors !== null" class="d-flex justify-content-between">
              <div v-if="animal.color_detail"><strong>Couleurs principales</strong> :<br> {{ animal.color_detail }}</div>
              <div v-if="animal.colors" class="text-center">
                <div v-for="color in animal.colors"  :key="color.id" class="rounded-circle mx-25 d-inline-block "
                     :style="'height: 20px; width: 20px; background-color: '+ getColorHexa(color)"
                :class="getColorHexa(color)==='#FFFFFF' ? 'border-dark': ''" ></div>
              </div>
            </b-list-group-item>
            <b-list-group-item v-if="animal.silhouette"><strong>Silhouette générale</strong> : {{ animal.silhouette}}</b-list-group-item>
            <b-list-group-item v-if="animal.song"><strong>Son</strong> : {{ animal.song}}</b-list-group-item>
            <b-list-group-item v-if="animal.male_female"><strong>Différence femelle / mâle</strong> : {{ animal.male_female}}</b-list-group-item>
            <b-list-group-item v-if="animal.polymorphism === true && animal.polymorphism_detail"><strong>Polymorphisme</strong> : {{ animal.polymorphism_detail}}</b-list-group-item>
          </b-list-group>
          <p class="text-justify mt-1">
            <span v-if="animal.identification"><strong>Comment l'identifier ? </strong><br>{{ animal.identification }}.</span>
            <span class="text-justify mt-1" v-if="animal.confusions && animal.confusions.length > 0">
            Attention cependant, <em>{{ animal.infos_taxon.lb_nom }}</em> peut être confondu avec :  <em>{{ animal.confusions.join(", ") }}.  </em>
            </span>
          </p>
        </TextBlock>

        <TextBlock class="mb-1" collapsable title="Mode de vie">
          <feather-icon
              slot="titleSpace"
              icon="HeartIcon"
              size="24"/>

          <p class="text-justify mt-1">
            <span v-if="animal.habitat"><em>{{ animal.infos_taxon.lb_nom }}</em>  vit généralement dans les habitats suivants : {{ animal.habitat}}.</span>
            <span v-if="animal.mode_reproduction"> Les individus sont {{ animal.mode_reproduction.toLowerCase() }}s.</span>
          </p>

          <b-list-group>
            <b-list-group-item v-if="animal.diet !== null">
              <strong>Régime alimentaire </strong>
              : {{ getDietStr(animal.diet)}}
              <JayaTooltip title="Régime alimentaire principal">
                <feather-icon
                    class="mr-25"
                    icon="HelpCircleIcon"/>
                <template #text>
                  <p v-for="dietChoice in dietChoices" :key="dietChoice.id">
                    <strong>{{dietChoice.name}}</strong> :
                    <span>{{dietChoice.description}}</span>
                  </p>
                </template>
              </JayaTooltip>
            </b-list-group-item>
            <b-list-group-item v-if="animal.daily_activity !== null">
              <strong>Période d'activité </strong>
              : {{ getDailyActivityStr(animal.daily_activity)}}
              <JayaTooltip title="Période d'activité principale">
                <feather-icon
                    class="mr-25"
                    icon="HelpCircleIcon"/>
                <template #text>
                  <p v-for="dailyActivityChoice in dailyActivityChoices" :key="dailyActivityChoice.id">
                    <strong>{{dailyActivityChoice.name}}</strong> :
                    <span>{{dailyActivityChoice.description}}</span>
                  </p>
                </template>
              </JayaTooltip>
            </b-list-group-item>
            <b-list-group-item v-if="animal.social !== null">
              <strong>Comportement social </strong>
              : {{ getSocialStr(animal.social) }}
              <JayaTooltip title="Comportement social">
                <feather-icon
                    class="mr-25"
                    icon="HelpCircleIcon"/>
                <template #text>
                  <p v-for="socialChoice in socialChoices" :key="socialChoice.id">
                    <strong>{{socialChoice.name}}</strong> :
                    <span>{{socialChoice.description}}</span>
                  </p>
                </template>
              </JayaTooltip>
            </b-list-group-item>
            <b-list-group-item v-if="animal.hibernation !== null"><strong>Hibernation</strong> : {{ getHibernationStr(animal.hibernation) }} </b-list-group-item>
            <b-list-group-item v-if="animal.migration !== null"><strong>Migration</strong> : {{ getMigrationStr(animal.migration) }} </b-list-group-item>
            <b-list-group-item v-if="animal.sleep"><strong>Sommeil</strong> : {{ animal.sleep }} </b-list-group-item>
            <b-list-group-item v-if="animal.life_expectancy"><strong>Espérance de vie</strong> : {{ parseDuration(animal.life_expectancy) }} </b-list-group-item>
          </b-list-group>
        </TextBlock>

        <TextBlock class="mb-1"
                   v-if="animal.biotic_interactions && animal.biotic_interactions.length > 0"
                   collapsable title="Biodiversité">
          <img slot="titleSpace" alt="Icone hauteur des plantes" class="icon"
               src="@/assets/images/icons/fauna/butterfly.svg">

          <div v-if="animal.biotic_interactions && animal.biotic_interactions.length > 0">
            <div class="d-flex mb-2 justify-content-start align-items-center">
              <JayaTooltip title="Interactions biotiques" class="pr-50">
                <feather-icon
                    icon="InfoIcon"
                    class="text-info"
                    size="20"/>
                <div slot="text">
                  <p>
                    Les données d'interaction entre espèces proviennent du programme "GloBI".
                    Global Biotic Interactions (GloBI) offre un accès libre à des données sur les interactions entre
                    espèces (par exemple, prédateur-proie, pollinisateur-plante, pathogène-hôte, parasite-hôte) en
                    combinant des ensembles de données ouverts existants à l'aide d'un logiciel libre.
                  </p>
                  <p>
                    Vous trouverez ici seulement les interactions entre espèces présentes dans les bases de données
                    faunistique et floristique de Hortilio.
                  </p>
                  <i>
                    Réference :
                    Jorrit H. Poelen, James D. Simons and Chris J. Mungall. (2014). Global Biotic Interactions:
                    An open infrastructure to share and analyze species-interaction datasets. Ecological Informatics.
                    <a class="cursor-pointer text-primary" @click="$openLinkJaya('https://doi.org/10.1016/j.ecoinf.2014.08.005')" target="_blank">https://doi.org/10.1016/j.ecoinf.2014.08.005.</a>
                  </i>
                </div>
              </JayaTooltip>
              <h4 class="plant-info mb-0">Interactions remarquables
              </h4>
            </div>
            <div v-for="(interaction, interactionIndex) in animal.biotic_interactions.filter(e => e.taxa.length && e.taxa.length > 0)" :key="interactionIndex" class="mb-1">
              <h5 class="mb-0"><span class="text-secondary">{{ formatInteractionName(interaction.interaction_type.verb_fr) }} :</span></h5>
              <b-list-group flush>
                <b-list-group-item
                    v-for="(taxon, index) in (expandedInteractions[interactionIndex] ? interaction.taxa : interaction.taxa.slice(0, 3))"
                    :key="`taxon-${index}`"
                    class="px-0 py-0">
                  <div v-if="taxon.animal !== null" class="d-flex justify-content-between align-items-center">
                    <div>
                      {{ formatTaxonName(taxon.animal.common_name) }}<br>
                    </div>
                    <b-button
                        class="p-25"
                        pill
                        variant="flat-dark"
                        @click="$router.push({name: 'animals', params: {animalId: taxon.animal.id}})">
                      <feather-icon
                          slot="titleSpace"
                          icon="ArrowRightCircleIcon"
                          size="20"/>
                    </b-button>
                  </div>
                  <div v-else-if="taxon.generic_plant !== null" class="d-flex justify-content-between align-items-center">
                    <div>
                      {{ formatTaxonName(taxon.generic_plant.common_name) }}<br>
                    </div>
                    <b-button
                        class="p-0"
                        pill
                        variant="flat-dark"
                        @click="$router.push({name: 'plants', params: {plantId: taxon.generic_plant.id}})">
                      <feather-icon
                          slot="titleSpace"
                          icon="ArrowRightCircleIcon"
                          size="20"/>
                    </b-button>
                  </div>
                  <div v-else>
                    <i>{{ taxon.lb_nom }}</i>
                  </div>
                </b-list-group-item>

                <b-list-group-item class="ml-2 text-primary px-0 pt-50" v-if="interaction.taxa.length > 5">
                  <b-button
                      class="p-0 font-weight-normal"
                      pill
                      @click="toggleInteractionDisplay(interactionIndex)"
                      variant="flat-secondary">
                    <span v-if="expandedInteractions[interactionIndex]">Voir moins</span>
                    <span v-else>Voir tout ({{ interaction.taxa.length }})</span>
                  </b-button>
                </b-list-group-item>
              </b-list-group>
            </div>
          </div>
        </TextBlock>
      </div>
    </div>

    <b-modal
        v-if="gotAnimal && hortiliObservation"
        ref="popUpAnimalObservation"
        centered
        hide-footer
        title="J'ai fait une observation"
        @observationDone="closePopUpAnimalObservation"
    >
      <HortiliObservation mode="create" :taxon="animal" @observationDone="closePopUpAnimalObservation"/>
    </b-modal>
  </div>
</template>

<script type="text/javascript">
import {BButton, BListGroup, BListGroupItem, BModal, BRow} from "bootstrap-vue";
import TextBlock from '@/components/global/JayaTextBlock.vue'
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
import {mapState} from "vuex";

export default {
  name: "animal-info",
  components: {
    BButton,
    BRow,
    BListGroup,
    BListGroupItem,
    BModal,
    TextBlock,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      popUpBigPictures: false,
      popUpPlantPhoto: {
        id: null,
        author: '',
        source: '',
        license: '',
        order: 0,
        manual_status: null,
        photo: null,
      },
      gotAnimal: false,
      animalPageId: -1,
      showAll: false,
      animalImageSwiperOption: {
        direction: 'horizontal',
        slidesPerView: 1,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }
      },
      animal: null,
      colorChoices: [
        { id: 1, name: "rouge", hexa: "#800000" },
        { id: 2, name: "orange", hexa: "#ffa500" },
        { id: 3, name: "jaune", hexa: "#ffd700" },
        { id: 4, name: "vert", hexa: "#065535" },
        { id: 5, name: "bleu", hexa: "#0a75ad" },
        { id: 6, name: "violet", hexa: "#800080" },
        { id: 7, name: "rose", hexa: "#ffb6c1" },
        { id: 8, name: "marron", hexa: "#654321" },
        { id: 9, name: "gris", hexa: "#bebebe" },
        { id: 10, name: "noir", hexa: "#000000" },
        { id: 11, name: "blanc", hexa: "#FFFFFF" },
        { id: 12, name: "beige", hexa: "#f5f5dc" },
        { id: 13, name: "turquoise", hexa: "#40e0d0" },
        { id: 14, name: "magenta", hexa: "#9f4576" },
        { id: 15, name: "indigo", hexa: "#4b0082" }
      ],
      dietChoices: [
        { id: 1, name: "Omnivore", description: "qui se nourrit indifféremment d'aliments très divers (herbes et chair, insectes et fruits, débris animaux et végétaux, etc.)." },
        { id: 2, name: "Carnivore", description: "qui se nourrit de proies animales vivantes." },
        { id: 3, name: "Herbivore", description: "qui se nourrit d'herbes et de plantes basses (pour les vertébrés - pour les insectes, on dit plutôt phytophage)." },
        { id: 4, name: "Granivore", description: "qui se nourrit de graines." },
        { id: 5, name: "Insectivore", description: "qui se nourrit d'insectes (ce mot s'emploie surtout pour les vertébrés ; pour les invertébrés, on dit plutôt entomophage)." },
        { id: 6, name: "Piscivore", description: "qui se nourrit de poissons." },
        { id: 7, name: "Nectarivore", description: "qui se nourrit de nectar." },
      ],
      socialChoices: [
        { id: 1, name: "Solitaire", description: "qui vit principalement seul, hors période de reproduction." },
        { id: 2, name: "Grégaire", description: "qui vit en groupe ou en communauté, mais sans structure sociale." },
        { id: 3, name: "Eusocial", description: "qui vit en société, avec une structure social, une séparation des tâches et ds individus fertiles et non-fertiles" },
      ],
      dailyActivityChoices: [
        { id: 1, name: "Diurne", description: "qui vit principalement la journée." },
        { id: 2, name: "Nocturne", description: "qui vit principalement la nuit." },
      ],
      hibernationChoices: [
        { id: 1, name: "Oui"},
        { id: 2, name: "Non"},
      ],
      migrationChoices: [
        { id: 1, name: "Oui"},
        { id: 2, name: "Non"},
      ],
      expandedInteractions: {},
    }
  },
  props: {
    title: {
      type: String,
      default: "Default Title"
    },
    animalId: {
      type: Number,
      default: -1
    },
    animalPage: {
      type: Boolean,
      default: false
    },
    hortiliObservation: {
      type: Boolean,
      default: true
    },
  },
  mounted() {
    this.animalPageId = this.animalId
  },
  computed: {
    ...mapState({
      user: state => state.account.user,
    }),
    animalPhotos() {
      this.gotAnimal // Force update animalPhoto when getPlant change
      if (this.animal.photos.length > 0) {
        return this.animal.photos
            .map(photo => process.env.VUE_APP_BACKEND_URL + '/..' + photo.photo_url + "&size=600")
      }
      return [require("@/assets/images/icons/fauna/butterfly.svg")]
    },
  },
  methods: {
    getColorHexa(color) {
      return this.colorChoices.find((e) => e.id === color).hexa
    },
    getColorStr(colorIds) {
      var colorMap = {};
      this.colorChoices.forEach(function(color) {
        colorMap[color.id] = color.name;
      });

      var colors = colorIds.map(function(id) {
        return colorMap[id];
      });
      return colors.join(", ");
    },
    getDietStr(dietId) {
      const dietChoices = this.dietChoices.find((e) => e.id === dietId)
      return dietChoices ? dietChoices.name : 'inconnu';
    },
    getSocialStr(socialId) {
      const socialChoices = this.socialChoices.find((e) => e.id === socialId)
      return socialChoices ? socialChoices.name : 'inconnu';
    },
    getDailyActivityStr(dailyActivityId) {
      const dailyActivityChoices = this.dailyActivityChoices.find((e) => e.id === dailyActivityId)
      return dailyActivityChoices ? dailyActivityChoices.name : 'inconnu';
    },
    getHibernationStr(hibernationId) {
      const hibernationChoices = this.hibernationChoices.find((e) => e.id === hibernationId)
      return hibernationChoices ? hibernationChoices.name : 'inconnu';
    },
    getMigrationStr(migrationId) {
      const migrationChoices = this.migrationChoices.find((e) => e.id === migrationId)
      return migrationChoices ? migrationChoices.name : 'inconnu';
    },
    parseSize(size) {
      if (size < 1) {
        size = size * 10
        return size.toString() + " millimètre(s)"
      } else if (size < 100) {
        return size.toString() + " centimètre(s)"
      } else {
        size = size / 100
        return size.toString() + " mètre(s)"
      }
    },
    parseWeight(weight) {
      if (weight < 1) {
        weight = weight * 1000
        return weight.toString() + " milligramme(s)"
      } else if (weight < 1000) {
        return weight.toString() + " gramme(s)"
      } else {
        weight = weight / 1000
        return weight.toString() + " kilogramme(s)"
      }
    },
    parseDuration(duration) {
      if (duration < 1) {
        duration = duration * 30
        return duration.toString() + " jour(s)"
      } else if (duration < 12) {
        return duration.toString() + " mois"
      } else {
        duration = duration / 12
        return duration.toString() + " année(s)"
      }
    },
    copyLink() {
      this.$copyLink("animals", this.animal.id)
    },
    clickx(e) {
      e.stopPropagation()
    },
    truncateSize(height) {
      if (height < 10) {
        return Math.round(height * 10) / 10
      }
      return Math.round(height)
    },
    showAllFamily() {
      this.showAll = true
    },
    isMonthsCorrect(monthsArray) {
      return this.$options.filters.numericMonthsArray(monthsArray).length > 0
    },
    showImageViewer() {
      let index = this.$refs.swiper.swiper.activeIndex
      this.$refs.viewer[index].$el.viewer.show()
    },
    getAlt(author, licence) {
      if (author && licence) {
        return 'Photo de ' + author + ', ' + licence
      } else if (author) {
        return 'Photo de ' + author
      }
      return licence ? licence : ''
    },
    closePopUpAnimalObservation() {
      this.$refs.popUpAnimalObservation.hide()
      this.$notify({
        time: 4000,
        color: "primary",
        title: "Observation",
        text: "Observation ajoutée, retrouvez la sur la liste des observations."
      })
    },
    displaySynonyms(synonyms) {
      return synonyms.filter(e => {
        return e === ""
      }).length !== synonyms.length
    },

    formatInteractionName(interactionName) {
      return interactionName ? `${interactionName.charAt(0).toUpperCase()}${interactionName.slice(1)}` : '';
    },

    formatTaxonName(taxonName) {
      const nameWithoutParentheses = taxonName.replace(/\(.*?\)/g, '');
      return nameWithoutParentheses.charAt(0).toUpperCase() + nameWithoutParentheses.slice(1);
    },

    toggleInteractionDisplay(interactionId) {
      this.$set(this.expandedInteractions, interactionId, !this.expandedInteractions[interactionId]);
    },
  },
  watch: {
    animalPageId(id) {
      if (id >= 0) {
        this.animal = []
        this.gotAnimal = false
        this.$store.dispatch("animal/get", {id: this.animalPageId})
            .then(response => {
              this.animal = response.data
              this.gotAnimal = true
            })
            .catch((error) => {
              this.animal = []
              if (this.animalPage) {
                if (error.response.status == 404) {
                  this.$router.push({name: "error-404"})
                }
                if (error.response.status == 429) {
                  this.$notify({
                    time: 10000,
                    title: "Limite atteinte",
                    text: "Vous avez atteint la limite quotidienne de visualisation de fiches animal, pour continuer, veuillez créer un compte"
                  })
                  this.$router.push({name: "accueil"})
                }
              } else {
                this.$notify({
                  time: 4000,
                  title: "Erreur",
                  text: "Une erreur est survenue au chargement de cette fiche animal",
                  color: "danger"
                })
              }
            })
      }
    }
  }
}
</script>

<style lang="scss">
.swiper-box {
  z-index: 0;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.animal-info {
  width: 100%;

  .card {
    background-color: transparent;
    box-shadow: none;

    .card-header {
      padding-bottom: 0;
    }

    .card-body {
      padding-top: 0;
    }
  }

  .list-group .list-group-item {
    background-color: transparent;
  }


  .icon {
    height: 30px;
  }

  .icon-small {
    height: 20px;
    width: 30px;
    padding-right: 5px;
  }

  .truncate-slot {
    .vs-list--slot {
      text-align: right;
      padding-left: 5px;
    }

    .list-titles {
      width: 100%
    }
  }

  h4 {
    color: rgba(var(--vs-dark), 1) !important;
    font-weight: 500;
    padding-top: 5px;
  }
}
</style>
