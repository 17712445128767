import axios from "@/axios.js"

export default {
    search(context, params) {
        return new Promise((resolve, reject) => {
            axios.get("api/custom_help_sheets/", {params: params})
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    get(context, id) {
        return new Promise((resolve, reject) => {
            let url = "api/custom_help_sheets/" + id.toString() + '/'
            axios.get(url)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    create(context, payload) {
        return new Promise((resolve, reject) => {
            axios.post("api/custom_help_sheets/", payload)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    update(context, payload) {
        return new Promise((resolve, reject) => {
            axios.patch("api/custom_help_sheets/" + payload.id.toString() + "/", payload)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    delete(context, id) {
        return new Promise((resolve, reject) => {
            let url = "api/custom_help_sheets/" + id.toString() + '/'
            axios.delete(url)
                .then((response) => {
                    resolve(response)
                    this.$emit("deleted")
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
}
