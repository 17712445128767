import Vue from 'vue'

Vue.filter('month', function (val, showYear = true) {
    val = String(val)
    const regx = /\w+\s(\w+)\s\d+\s(\d+)./;
    if (!showYear) {
        return regx.exec(val)[1];
    } else {
        return regx.exec(val)[1] + ' ' + regx.exec(val)[2];
    }
}),

    Vue.filter('day', function (val) {
        val = String(val)
        return val.split(' ')[2] + ' ' + val.split(' ')[1]
    }),

// Transforms a date periode string from jj/XX-jj/YY to an array of month numbers [XX, XX+1, XX+2, .., YY]
    Vue.filter('numericMonthsArray', function (val) {
        const result = [];
        for (let period of val) {
            let start_month = parseInt(period.start_date.split('/')[1], 10)
            let end_month = parseInt(period.end_date.split('/')[1], 10)
            if (end_month != start_month) {
                let add_month = 0;
                if (start_month < end_month) {
                    for (add_month = start_month; add_month <= end_month; add_month++) {
                        result.push(add_month)
                    }
                } else {
                    for (add_month = start_month; add_month <= start_month + end_month + 1; add_month++) {
                        result.push(add_month % 13)
                    }
                }
            } else {
                result.push(start_month)
            }

        }
        return result
    }),

// Transforms a string of rgbs to an array of rgb colors
    Vue.filter('colorsArray', function (val) {

        const result = [];
        for (let color of val) {
            result.push("(" + color.red.toString() + "," + color.green.toString() + "," + color.blue.toString() + ")")
        }
        return result
    })
