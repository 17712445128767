/*=========================================================================================
  File Name: moduleLocalAuthority.js
  Description: Local Authority Module
  ----------------------------------------------------------------------------------------
  Item Name: JAYA - GARDEN
  Author: JAYA SAS
  Author URL: https://jaya-garden.fr
==========================================================================================*/

import state from './moduleLocalAuthorityState.js'
import mutations from './moduleLocalAuthorityMutations.js'
import actions from './moduleLocalAuthorityActions.js'
import getters from './moduleLocalAuthorityGetters.js'

export default {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
