export default {
  ADD_NOTES(state, payload) {
    let newArray = []
    for (const newNote of payload) {
      //skip and increment quantity of multiple instances of same spieces and same task
      if (newNote.kind === 'State' || newNote.kind === 'Harv') {
        if (state.notes.some(note => note.state === newNote.state && new Date(note.date).setHours(0,0,0,0) === new Date(newNote.date).setHours(0,0,0,0) && note.plant_name ===
          newNote.plant_name)) {
          let objectToUpdate = state.notes.find(note => note.state === newNote.state && new Date(note.date).setHours(0,0,0,0) === new Date(newNote.date).setHours(0,0,0,0) && note.plant_name ===
            newNote.plant_name)
          objectToUpdate.quantity += 1
          if(newNote.kind === 'Harv') {
            objectToUpdate.mass += newNote.mass
            objectToUpdate.number += newNote.number
          }
          continue
        }
        if (newArray.some(note => note.state === newNote.state && new Date(note.date).setHours(0,0,0,0) === new Date(newNote.date).setHours(0,0,0,0) &&
          note.plant_name === newNote.plant_name)) {
          let objectToUpdate = newArray.find(note => note.state === newNote.state && new Date(note.date).setHours(0,0,0,0) === new Date(newNote.date).setHours(0,0,0,0) &&
            note.plant_name === newNote.plant_name)
          objectToUpdate.quantity += 1
          if(newNote.kind === 'Harv') {
            objectToUpdate.mass += newNote.mass
            objectToUpdate.number += newNote.number
          }
          continue
        }
      }
      // skip already added personal notes
      if (newNote.kind === 'Note' &&
        state.notes.some(note => note.id === newNote.id)) {
        continue
      }
      newNote.quantity = 1
      newArray.push(newNote)
    }
    state.notes.push.apply(state.notes, newArray)
    state.notes = state.notes.sort((a, b) => new Date(b.date) - new Date(a.date))
  },
  ADD_NOTE(state, payload) {
    payload.kind = 'Note'
    state.notes.push(payload)
    state.notes = state.notes.sort((a, b) => new Date(b.date) - new Date(a.date))
  },
  EDIT_NOTE(state, payload) {
    payload.kind = 'Note'
    let editIndex = state.notes.findIndex(note => note.id === payload.id)
    state.notes[editIndex] = payload
    state.notes = state.notes.sort((a, b) => new Date(b.date) - new Date(a.date))
  },
  REMOVE_NOTE(state, payload) {
    let removeIndex = state.notes.findIndex(note => note.id === payload.id)
    state.notes.splice(removeIndex, 1)
    state.notes = state.notes.sort((a, b) => new Date(b.date) - new Date(a.date))
  },
  CLEAR_NOTES(state) {
    state.notes = []
  },
  ADD_REMINDER(state, payload) {
    let editIndex = state.notes.findIndex(note => note.id === payload.note)
    state.notes[editIndex].reminders = [payload]
  },
  REMOVE_REMINDER(state, payload) {
    let removeIndex = state.notes.findIndex(note => note.id === payload)
    state.notes[removeIndex].reminders.length = 0
  },
}
