/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
    ADD_TASK(state, task) {
        state.tasks.push(task)
    },
    SET_TASKS(state, tasks) {
        state.tasks = tasks
    },
    SET_LABELS(state, labels) {
        state.taskLabels = labels
    },
    UPDATE_TASK(state, task) {
        const taskIndex = state.tasks.findIndex((e) => e.id == task.id)
        const color = state.colors.find(index => index.label == task.label).color
        task.classes = 'event-' + color
        Object.assign(state.tasks[taskIndex], task)
    },
    REMOVE_TASK(state, taskId) {
        const taskIndex = state.tasks.findIndex((e) => e.id == taskId)
        state.tasks.splice(taskIndex, 1)
    },
    ADD_COLOR(state, task) {
        const taskIndex = state.tasks.findIndex((e) => e.id == task.id)
        const taskStored = state.tasks[taskIndex]
        const color = state.colors.find(index => index.label == task.label).color
        taskStored.classes = 'event-' + color
        Object.assign(state.tasks[taskIndex], taskStored)
    }
}
