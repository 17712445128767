import axios from "@/axios.js"

export default {
    search(context, params) {
        return new Promise((resolve, reject) => {
            axios.get("/plants/", {params: params})
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    searchAdvice(context, params) {
        return new Promise((resolve, reject) => {
            axios.get("/plants/interaction_advices/", {params: params})
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    get(context, id) {
        return new Promise((resolve, reject) => {
            let url = "/plants/" + id.id.toString() + '/'
            axios.get(url)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
}
