import state from './moduleFeedbackState.js'
import mutations from './moduleFeedbackMutations.js'
import actions from './moduleFeedbackActions.js'
import getters from './moduleFeedbackGetters.js'

export default {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
