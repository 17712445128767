import axios from "@/axios.js"

export default {
    search(context, params) {
        return new Promise((resolve, reject) => {
            axios.get("/zipcodes/", {params: params})
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }
}
