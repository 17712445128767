import state from './moduleZipCodeState.js'
import mutations from './moduleZipCodeMutations.js'
import actions from './moduleZipCodeActions.js'
import getters from './moduleZipCodeGetters.js'

export default {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
