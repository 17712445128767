/*=========================================================================================
  File Name: moduleNotes.js
  Description: Local Authority Module
  ----------------------------------------------------------------------------------------
  Item Name: JAYA - GARDEN
  Author: JAYA SAS
  Author URL: https://jaya-garden.fr
==========================================================================================*/

import state from './moduleNotesState.js'
import mutations from './moduleNotesMutations.js'
import actions from './moduleNotesActions.js'
import getters from './moduleNotesGetters.js'

export default {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
