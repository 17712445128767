import state from './moduleGDPRState.js'
import mutations from './moduleGDPRMutations.js'
import actions from './moduleGDPRActions.js'
import getters from './moduleGDPRGetters.js'

export default {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
