import axios from "@/axios.js"

export default {
    fetchNotifications(context, params) {
        return new Promise((resolve, reject) => {
            axios.get("api/notifications/", {params: params})
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    putNotification(context, id) {
        let url = "api/notifications/" + id.toString() + "/"
        return new Promise((resolve, reject) => {
            let payload = {seen: true}
            axios.put(url, payload)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    createDevice(context, payload) {
        return new Promise((resolve, reject) => {
            axios.post("api/firebase_devices/", payload)
                .then((response) => {
                    resolve(response)
                })
                .catch(() => {
                    reject()
                })
        })
    },
    fetchDevices({commit, dispatch, rootState}) {
        // fetch firebase devices
        return new Promise((resolve, reject) => {
            axios.get("api/firebase_devices/")
                .then(response => {
                    if (rootState.account.firebaseToken.value && rootState.account.firebaseToken.id === null) {
                        const tokens = response.data.filter(e => { return e.token === rootState.account.firebaseToken.value})
                        if (tokens.length > 0) {
                            commit('account/SET_FIREBASE_TOKEN_ID', tokens[0].id, {root: true})
                            resolve()
                        } else {
                            let payload = {token: rootState.account.firebaseToken.value}
                            if (window.cordova && window.cordova.platformId === "ios") {
                                payload.device_type = 2
                            }
                            if (window.cordova && window.cordova.platformId === "android") {
                                payload.device_type = 1
                            }
                            dispatch("createDevice", payload)
                                .then((createToken) => {
                                    commit("account/UPDATE_FIREBASE_TOKEN", createToken.data.token, {root: true})
                                    commit("account/SET_FIREBASE_TOKEN_ID", createToken.data.id, {root: true})
                                    resolve()
                                })
                        }
                    }
                })
                .catch(() => {
                    reject()
                })
        })
    },
    updateDevice(context, payload) {
        //update firebase device
        const url = "api/firebase_devices/" + payload.id.toString() + "/"
        return new Promise((resolve, reject) => {
            axios.patch(url, payload)
                .then((response) => {
                    resolve(response)
                })
                .catch(() => {
                    reject()
                })
        })
    }
}
