import axios from "@/axios.js"

export default {
    search(context, params) {
        return new Promise((resolve, reject) => {
            axios.get("/fauna/", {params: params})
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    get(context, id) {
        return new Promise((resolve, reject) => {
            let url = "/fauna/" + id.id.toString() + '/'
            axios.get(url)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    fetchObservations({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.get('/observations/hortilio/', {params: payload})
                .then(response => {
                    if(!response.data.previous)
                        commit('CLEAR_OBSERVATIONS')
                    commit('ADD_OBSERVATIONS', response.data.results)
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    getObservation(context, payload) {
        return new Promise((resolve, reject) => {
            axios.get('/observations/hortilio/' + payload + '/')
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    addObservation({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post('/observations/hortilio/', payload)
                .then(response => {
                    commit('ADD_OBSERVATION', response.data)
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    editObservation({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.patch('/observations/hortilio/' + payload.id + '/', payload)
                .then(response => {
                    commit('EDIT_OBSERVATION', response.data)
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    deleteObservation({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.delete('/observations/hortilio/' + payload.id +'/')
                .then(response => {
                    commit('REMOVE_OBSERVATION', payload)
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
}
