/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js"

export default {
    addTask({commit}, task) {
        return new Promise((resolve, reject) => {
            axios.post("/api/tasks/", task)
                .then((response) => {
                    commit('ADD_TASK', Object.assign(task, {id: response.data.id}))
                    commit('ADD_COLOR', task)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    fetchTasks({commit}) {
        return new Promise((resolve, reject) => {
            axios.get("/api/tasks/")
                .then((response) => {
                    for (let j = 0; j < response.data.length; j++) {
                        response.data[j].endDate = new Date(response.data[j].endDate)
                        response.data[j].startDate = new Date(response.data[j].startDate)
                    }
                    commit('SET_TASKS', response.data)
                    for (let i = 0; i < response.data.length; i++) {
                        commit('ADD_COLOR', response.data[i])
                    }
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    fetchTaskLabels({commit}) {
        return new Promise((resolve, reject) => {
            axios.get("/api/apps/calendar/labels")
                .then((response) => {
                    commit('SET_LABELS', response.data)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    editTask({commit}, task) {
        return new Promise((resolve, reject) => {
            axios.patch(`/api/tasks/${task.id}/`, task)
                .then((response) => {
                    // Convert Date String to Date Object
                    let task = response.data
                    task.startDate = new Date(task.startDate)
                    task.endDate = new Date(task.endDate)
                    commit('UPDATE_TASK', task)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    removeTask({commit}, taskId) {
        return new Promise((resolve, reject) => {
            axios.delete(`/api/tasks/${taskId}`)
                .then((response) => {
                    commit('REMOVE_TASK', taskId)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    taskDragged({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.patch(`/api/tasks/${payload.task.id}/`, {payload: payload})
                .then((response) => {

                    // Convert Date String to Date Object
                    let task = response.data
                    task.startDate = new Date(task.startDate)
                    task.endDate = new Date(task.endDate)

                    commit('UPDATE_TASK', task)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
}
