import state from './moduleVersionState.js'
import mutations from './moduleVersionMutations.js'
import actions from './moduleVersionActions.js'
import getters from './moduleVersionGetters.js'

export default {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
