<template>
  <div class="jaya-divider d-flex justify-content-center align-items-center my-50">
    <span :class="'flex-grow-1 border-' + thickness + ' border-bottom-' + color"/>
    <span v-if="text" :class="'pr-1 pl-1 text-' + color">{{ text }}</span>
    <span :class="'flex-grow-1 border-' + thickness + ' border-bottom-' + color"/>
  </div>
</template>

<script>
export default {
  name: "JayaDivider",

  props: {
    text: {
      type: [String, Number],
      default: null
    },

    color: {
      type: [String, Number],
      default: "light",
    },

    thickness: {
      type: [String, Number],
      default: "1",
    }
  }
}

</script>