<template>
  <validation-observer v-slot="{ handleSubmit, invalid }">
    <p class="text-center">
      <span class="font-weight-bold"> {{ taxon.common_name }} </span><br>
      <i class="text-italic"> {{ taxon.infos_taxon.lb_nom }} </i> <br>
    </p>

    <b-alert
        v-if="modeValue !== 'display'"
        class="text-center p-1"
        show
        variant="info">
      <strong>Merci pour votre contribution !</strong> On compte sur vous pour saisir les informations les plus fiables
      possible.
    </b-alert>
    <b-form-group label="Où avez-vous fait l'observation ?" v-if="modeValue === 'create'" class="font-weight-bold">
      <validation-provider rules="required" v-slot="{ errors }" name="Location">
        <b-form-radio-group id="radio-group-1" v-model="location">
          <b-form-radio value="in_garden" class="font-weight-normal">Dans mon jardin</b-form-radio>
          <b-form-radio value="outside_garden" class="font-weight-normal">En dehors de mon jardin</b-form-radio>
        </b-form-radio-group>
        <b-form-text class="text-danger">{{ errors[0] }}</b-form-text>
      </validation-provider>
    </b-form-group>


    <b-form @submit.prevent="modeValue === 'display' ? null : handleSubmit(onSubmit)" v-if="location==='in_garden'"
            class="">

      <jaya-divider :thickness="1" color="primary" text="Dans mon jardin" v-if="modeValue === 'create'"/>
      <viewer v-if="observationPhoto" :images="[observationPhoto.url]"
              class="d-flex justify-content-center w-full mb-2">
        <img
            :key="observationPhoto.url"
            :src="observationPhoto.url"
            alt="photo observation"
            style="max-width: 100%; max-height: 250px;">
      </viewer>
      <div class="d-flex flex-column">
        <b-button
          class="h-full w-75 align-self-center"
          variant="outline-primary"
          @click="openPhotoModal"
          v-if="modeValue !== 'display'"
      >
        <feather-icon
            icon="CameraIcon"
            size="16"
        />
        <span class="align-middle ml-50"> {{ observationPhoto ? 'Modifier la photo' : 'Ajouter une photo' }} </span>
      </b-button>
        <b-button class="h-full max-w-50 max-w-sm align-self-center"
                variant="outline-primary"
                @click="promptAnimal=true"
                v-else
      >
        <feather-icon
            icon="ChevronsRightIcon"
            size="16"
        />
        <span class="align-middle ml-50"> Voir la fiche </span></b-button>
        <span class="text-danger text-center" v-if="!observationPhoto">N'hésitez pas ajouter une photo si vous avez réussi à immortaliser <em>{{taxon.common_name}}</em> lors de l'observation.
        </span>
      </div>

      <b-form-group label="Jardin" class="mt-1">
        <validation-provider rules="required" v-slot="{ errors }" name="Garden">
          <b-form-select :disabled="modeValue === 'display'" v-model="form.garden" :options="gardenChoices"/>
          <b-form-text class="text-danger">{{ errors[0] }}</b-form-text>
        </validation-provider>
      </b-form-group>

      <b-form-group label="Date et heure de l'observation">
        <validation-provider rules="required" v-slot="{ errors }" name="Date">
          <flat-pickr :disabled="modeValue === 'display'" v-model="form.timestamp" :config="dateConfig"
                      class="form-control"></flat-pickr>
          <b-form-text class="text-danger">{{ errors[0] }}</b-form-text>
        </validation-provider>
      </b-form-group>

      <b-form-group label="Nombre d'individus observés">
        <validation-provider rules="required|integer|min_value:1|max_value:100" v-slot="{ errors }" name="Quantity">
          <b-form-input type="number" v-model="form.quantity" :disabled="modeValue==='display'"/>
          <b-form-text class="text-danger">{{ errors[0] }}</b-form-text>
        </validation-provider>
      </b-form-group>

      <b-form-group label="Comportement">
        <validation-provider rules="required" v-slot="{ errors }" name="Behaviour">
          <b-form-select v-model="form.behaviour" :options="behaviourChoices" :disabled="modeValue==='display'"/>
          <b-form-text class="text-danger">{{ errors[0] }}</b-form-text>
        </validation-provider>
      </b-form-group>

      <b-form-group label="Notes">
        <validation-provider v-slot="{ errors }" name="Notes">
          <b-form-textarea v-model="form.notes" :disabled="modeValue==='display'"/>
          <b-form-text class="text-danger">{{ errors[0] }}</b-form-text>
        </validation-provider>
      </b-form-group>

      <div class="text-center">
        <b-button v-if="modeValue !== 'display'" type="submit" variant="primary"
                  :disabled="invalid">
          {{ modeValue === 'create' ? "Déclarer l'observation" : "Mettre à jour l'observation" }}
        </b-button>

      </div>
    </b-form>
    <div class="text-center">
      <b-button v-if="modeValue === 'display'" @click="setUpdateMode" class="m-50">
        Modifier l'observation
      </b-button>
      <b-button v-if="modeValue === 'display'" @click="promptDelete=true" class="btn-danger m-50">
        Supprimer l'observation
      </b-button>
    </div>

    <div v-if="modeValue==='create' && location==='outside_garden'" class="text-justify">
      <jaya-divider :thickness="1" color="primary" text="En dehors de mon jardin"/>
      <p>Vous pouvez vous rendre sur l'application <strong>INPN Espèces</strong>, développée par le
        <em>Museum National d'Histoire Naturelle.</em></p>
      <p>Véritable outil de sensibilisation et de connaissance de la biodiversité française, INPN Espèces vous permet
        de découvrir, depuis votre mobile, la diversité des espèces présentes autour de vous et de participer à
        l'inventaire de la biodiversité de votre commune.</p>

      <div class="text-center">
        <b-button variant="primary" @click="openLinkJaya(getINPNLink())">
          Aller sur <strong>INPN Espèces</strong>
        </b-button>
      </div>
    </div>

    <JayUpload :active.sync="promptUploadPhoto"
               title="Changer de photo"
               @on-error="observationPhoto = null"
               @on-success="addPhoto"
    />
    <b-modal
        v-model="promptAnimal"
        centered
        hide-footer
        title="Informations détaillées"
        variant="primary">
      <AnimalInfo v-if="promptAnimal" :animalId="taxon.id" :animal-page="true" :hortiliObservation="false"/>
    </b-modal>
    <b-modal
        v-model="promptDelete"
        centered
        hide-footer
        title="Suppression d'une observation"
        variant="primary">
      <div class="text-center">
        <feather-icon
            icon="AlertTriangleIcon"
            size="16"
            class="text-danger mr-50"
        />
        Attention, vous êtes sur le point de supprimer une observation, cette action est définitive.
      </div>
      <div class="w-100 d-flex justify-content-end">
        <b-button class="btn-danger mt-50 mr-50" @click="deleteObservation">
          Supprimer
        </b-button>
        <b-button class="mt-50" @click="promptDelete=false">
          Annuler
        </b-button>
      </div>
    </b-modal>
  </validation-observer>
</template>

<script>
import {ref, reactive, computed} from '@vue/composition-api'
import {extend, ValidationObserver, ValidationProvider} from 'vee-validate'
import {
  BForm,
  BFormGroup,
  BFormSelect,
  BFormText,
  BFormInput,
  BFormRadio,
  BFormRadioGroup,
  BFormTextarea,
  BButton,
  BAlert, BModal
} from "bootstrap-vue";
import FlatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import {max_value, min_value, integer, required} from "vee-validate/dist/rules";
import {openLinkJaya} from "@/globalMethods";
import store from '@/store'
import JayUpload from "@/components/global/JayUpload.vue";
import AnimalInfo from "@/components/animals/AnimalInfo.vue";

extend('min_value', {...min_value, message: "Ce champ doit être supérieur à {min}"});
extend('max_value', {...max_value, message: "Ce champ doit doit être inférieur à {max}"});
extend('integer', {...integer, message: "Ce champ doit être un nombre entier"});
extend('required', {...required, message: "Ce champ est requis"});

export default {
  name: 'HortiliObservation',
  methods: {openLinkJaya},
  components: {
    BModal,
    AnimalInfo,
    JayUpload,
    BAlert,
    BForm,
    BFormGroup,
    BFormSelect,
    BFormText,
    BFormInput,
    BFormRadio,
    BFormRadioGroup,
    BFormTextarea,
    BButton,
    FlatPickr,
    ValidationObserver,
    ValidationProvider
  },
  props: {
    mode: {
      type: String,
      required: true,
      validator: value => ['create', 'edit', 'display'].includes(value)
    },
    initialData: {
      type: Object,
      default: () => ({})
    },
    taxon: {
      type: Object,
      required: true
    },
  },
  setup(props, context) {
    const location = ref(null)
    let promptUploadPhoto = ref(false)
    let promptAnimal = ref(false)
    let promptDelete = ref(false)
    const gardens = computed(() => store.state.garden.gardens)
    const gardenChoices = computed(() => gardens.value.map((e) => {
      return {value: e.id, text: e.name}
    }))
    const activeGarden = computed(() => gardens.value.find((e) => e.is_active))
    let observationPhoto = ref(null)
    let modeValue = ref(props.mode);
    if (modeValue.value !== "create") {
      location.value = "in_garden"
    }
    if (props.initialData.image_urls) {
      observationPhoto.value = {
        url: process.env.VUE_APP_BACKEND_URL + '/..' + props.initialData.image_urls[0],
        id: props.initialData.images[0]
      }
    }

// Create a Date object with these components
    function formatDateToISOWithoutSeconds(dateValue=null) {
      // If no date is set create a new one now
      let date = dateValue ? new Date(dateValue) : new Date
      const yyyy = date.getFullYear();
      const mm = String(date.getMonth() + 1).padStart(2, '0');  // January is 0!
      const dd = String(date.getDate()).padStart(2, '0');
      // If date is set, chances are it comes with a bad timezone
      const hh = dateValue ?  String(date.getHours()).padStart(2, '0') - 2 : String(date.getHours()).padStart(2, '0');
      const min = String(date.getMinutes()).padStart(2, '0');
      return `${yyyy}-${mm}-${dd}T${hh}:${min}`;
    }

    const form = reactive({
      taxon: props.initialData.taxon || props.taxon.id,
      garden: props.initialData.garden || activeGarden.value.id,
      timestamp: formatDateToISOWithoutSeconds(props.initialData.timestamp) || formatDateToISOWithoutSeconds(),
      quantity: props.initialData.quantity || 1,
      behaviour: props.initialData.behaviour || 1,
      notes: props.initialData.notes || ""
    })
    const setUpdateMode = () => {
      modeValue.value = 'edit'
    }
    const behaviourChoices = computed(() => [
      {value: 0, text: 'Inconnu'},
      {value: 1, text: 'Non renseigné'},
      {value: 5, text: 'Construction de toile'},
      {value: 8, text: 'Repas / chasse'},
      {value: 9, text: 'Passage en vol'},
      {value: 14, text: 'Nourrissage des jeunes'},
      {value: 15, text: 'Posé'},
      {value: 16, text: 'En déplacement'},
      {value: 17, text: 'Repos'},
      {value: 18, text: 'Chant'},
      {value: 19, text: 'Accouplement'},
    ])

    const dateConfig = {
      enableTime: true,
      maxDate: new Date(),
    };

    const onSubmit = async () => {
      if (modeValue.value === 'create') {
        // Create observation
        let payload = {}
        if (observationPhoto.value) {
          payload = {...form, images: [observationPhoto.value.id]}
        } else {
          payload = form
        }
        store.dispatch('animal/addObservation', payload)
            .then(() => {
              context.emit("observationDone", 'create')
            })

      } else if (modeValue.value === 'edit') {
        // Update observation
        let payload = {}
        if (observationPhoto.value) {
          payload = {...form, images: [observationPhoto.value.id], id: props.initialData.id}
        } else {
          payload = {...form, id: props.initialData.id}
        }
        store.dispatch('animal/editObservation', payload)
            .then(() => {
              context.emit("observationDone", 'edit')
            })
      }
    }

    const deleteObservation = async () => {
      store.dispatch('animal/deleteObservation', {id: props.initialData.id})
          .then(() => {
            context.emit("observationDone", 'delete')
          })
    }

    const getINPNLink = () => {
      if (window.cordova) {
        if (window.cordova.platformId === "android") {
          return 'https://play.google.com/store/apps/details?id=fr.mnhn.inpnespeces'
        }
        if (window.cordova.platformId === "ios") {
          return 'https://apps.apple.com/fr/app/inpn-especes/id1111609919?l=fr&ls=1'
        }
      }
      return 'https://inpn.mnhn.fr/accueil/participer/inpn-especes'
    }
    const addPhoto = (photo) => {
      observationPhoto.value = photo
    }
    const openPhotoModal = () => {
      promptUploadPhoto.value = true
    }
    return {
      location,
      form,
      gardenChoices,
      behaviourChoices,
      dateConfig,
      observationPhoto,
      onSubmit,
      getINPNLink,
      addPhoto,
      promptUploadPhoto,
      promptAnimal,
      promptDelete,
      modeValue,
      openPhotoModal,
      setUpdateMode,
      deleteObservation
    }
  }
}
</script>

<style>
.custom-select:disabled {
  color: #6e6b7b !important;
}
</style>