<template>
  <b-modal
      v-model="cookieMessagePrompt"
      size="lg"
      title="Ce site n'utilise que des cookies indispensables !"
      scrollable
      ok-only
      ok-title="J'ai compris"
  >
    <div class="d-flex align-items-center">
      <feather-icon
          icon="InfoIcon"
          size="60"
          class="mx-1 text-secondary"
      />
      <b-card-text>
        Ils permettent notamment de vous identifier pour accéder à votre compte et de limiter la vulnérabilité des
        systèmes d'authentification web.
      </b-card-text>
    </div>
    <b-button variant="flat-primary" @click="cookiePolicyPrompt=!cookiePolicyPrompt">
      En savoir plus
    </b-button>
    <GdprDocViewer doc_type="cookie-policy" v-show="cookiePolicyPrompt" class="m-1"/>
  </b-modal>
</template>

<script>
import GdprDocViewer from "@/views/gdpr/GdprDocViewer";
import {BButton, BCardText, BModal} from "bootstrap-vue";

export default {
  name: "CookieMessage",
  components: {
    GdprDocViewer,
    BModal,
    BCardText,
    BButton,
  },
  data() {
    return {
      cookiePolicyPrompt: false,
    }
  },
  computed: {
    cookieMessagePrompt: {
      get(){
        const cookie_date = new Date(localStorage.getItem("cookie_date"));
        const delta_t_cookie = new Date().getTime() - cookie_date.getTime();
        if (delta_t_cookie > 1000 * 3600 * 24 * 30 * 6) {
          const today = new Date();
          localStorage.setItem("cookie_date", today.toString());
          return true
        }
        return false
      },
      set(cookieMessagePrompt){
        return cookieMessagePrompt
      }
    }
  }
}
</script>

<style scoped>

</style>