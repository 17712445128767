import axios from "@/axios.js"

export default {
    postIdentification(context, params) {
        return new Promise((resolve, reject) => {
            axios.post('plant_identifications/', params)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    updateIdentification(context, params) {
        return new Promise((resolve, reject) => {
            axios.patch('plant_identifications/' + params.id.toString() + '/', params)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }
}
