export default {
    UPDATE_FILTERS(state, values) {
        state.filters = values['filters']
    },

    ADD_OBSERVATIONS(state, payload) {
        state.observations.push.apply(state.observations, payload)
        state.observations = state.observations.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
    },
    ADD_OBSERVATION(state, payload) {
        payload.kind = 'Observation'
        state.observations.push(payload)
        state.observations = state.observations.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
    },
    EDIT_OBSERVATION(state, payload) {
        payload.kind = 'Observation'
        let editIndex = state.observations.findIndex(observation => observation.id === payload.id)
        state.observations[editIndex] = payload
        state.observations = state.observations.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
    },
    REMOVE_OBSERVATION(state, payload) {
        let removeIndex = state.observations.findIndex(observation => observation.id === payload.id)
        state.observations.splice(removeIndex, 1)
        state.observations = state.observations.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
    },
    CLEAR_OBSERVATIONS(state) {
        state.observations = []
    },
}